<template>
  <div>
    <div class="card" style="margin-top: 1rem">
      <div class="card-header">
        <h5>{{ $t("base_source_date.mongo_base") }}</h5>
      </div>
      <div class="card-body">
        <div class="mongo">
          <div class="info" v-if="mongoStats.length">{{ $t("base_source_date.overall_volume") }}:
            {{ mongoStats[0].data_size }} </div>
          <div class="info" v-if="mongoStats.length">{{ $t("base_source_date.text_data") }}:
            {{ mongoStats[0].collections[0].size }}</div>
          <div class="info" v-if="mongoStats.length">{{ $t("base_source_date.images") }}:
            {{ mongoStats[0].collections[1].size }}</div>
          <div class="info" v-if="mongoStats.length">{{ $t("base_source_date.number_of_lines") }}:
            {{ beautyNumbers(mongoStats[0].count) }}</div>
        </div>
      </div>

      <div class="card-header">
        <h5>{{ $t("base_source_date.elastic_search_system") }}</h5>
      </div>
      <div class="card-body">
        <div class="elastic">
          <div class="info">{{ $t("base_source_date.overall_volume") }}: {{ elasticStats.size }}</div>
          <div class="info">{{ $t("base_source_date.number_of_lines") }}: {{ beautyNumbers(elasticStats.count) }}</div>
        </div>
      </div>
    </div>

    <div class="card" style="margin-top: 2rem">
      <div class="card-header"><h5>{{ $t("base_source_date.information_by_country") }}</h5></div>
      <div class="header" style="height: 3rem; align-items: center;">
        <div class="sortButt first">
          <div class="searchText" style="cursor: pointer;padding-left: 20px">{{ $t("base_source_date.country_name") }}</div>
          <div>
            <input :placeholder="this.$t('base_source_date.search')"
                     class="searchInput form-control"
                     v-model="inputValue"
                     icon-right="times"
                     icon-right-clickable
                     @input="inputSearch"
                     @icon-right-click="clearInputVal">
          </div>
        </div>
        <div class="sortButt" :content="this.$t('base_source_date.sort')" v-tippy="{ placement : 'top',  arrow: true }">
          <div class="sortButt" style="display: flex;justify-content: center; width: 100%" @click="sortByPrefix">
            <div style="cursor: pointer;">{{ $t("base_source_date.prefix_isdn") }}</div>
            <i v-if="sortedByPrefix.enable" class="p-t-5 feather" :class="{'icon-chevron-up': sortedByPrefix.fromAtoB, 'icon-chevron-down': !sortedByPrefix.fromAtoB}" style="margin-left: 3px"> </i>
          </div>
        </div>
        <div class="sortButt" :content="this.$t('base_source_date.sort')" v-tippy="{ placement : 'top',  arrow: true }">
          <div  style=" margin-right: 15px;margin-left:20px; display: flex;justify-content: center; cursor: pointer" @click="sortByCount">
            <div >{{ $t("base_source_date.number_of_lines") }}</div>
            <i v-if="sortedByCount.enable" class="p-t-5 feather" :class="{'icon-chevron-up': sortedByCount.fromAtoB, 'icon-chevron-down': !sortedByCount.fromAtoB}" style="margin-left: 3px"> </i>
          </div>
        </div>

      </div>
      <div class="scroll-area">
        <div v-for="(country, index ) in countries" :key="index">
          <div class="panel-block country" style="display: flex; align-items: center">
            <div class="tableBlock" v-if="country.countries.length === 1" style="display: flex;">
              <country-flag :country='country.countries[0].code' size='normal' style="margin-top: -10px;"/>
              <div class="sortButt" style="width: 80%; z-index: 100">
                <div  style="padding-left: 10px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; ">{{country.countries[0].country}}</div>
              </div>
            </div>
            <div class="tableBlock" v-else>
              <div v-for="(item, index) in country.countries" :key="index" style="display: flex">
                <country-flag :country='item.code' size='normal' style="margin-top: -10px;"/>
                <div class="sortButt" style="width: 80%; z-index: 100">
                  <div  style="padding-left: 10px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{item.country}}</div>
                </div>
              </div>
            </div>
            <div class="tableBlock" style="margin-left: -10px; display: flex;justify-content: center">+{{country.prefix}}</div>
            <div class="tableBlock" style="display: flex;justify-content: center">{{beautyNumbers(country.count)}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="info" style="margin-top: 1rem" v-if="env.dev">{{ $t("base_source_date.total_count") }} {{ count }}</div>






    <div class="card" style="margin-top: 1rem">
      <div class="card-header">
        <h5>{{ $t('billing.using_date') }}</h5>
      </div>
      <div class="card-body">

        <div class="block">
          <div style="margin-bottom: 0.5rem">
            <h6 >{{ $t('billing.period_selection') }}</h6>
          </div>
          <div class="d-lg-flex justify-content-between d-block">
            <date-range-picker
                class="d-flex"
                style="margin-bottom: 0.5rem; z-index: 5"
                ref="picker"
                :ranges="calendarTranslate.ranges[$i18n.locale]"
                v-model="dateRange"
                :opens="'right'"
                :locale-data="calendarTranslate.calendar[$i18n.locale]"
            >
              <div slot="footer" slot-scope="data" class="slot d-lg-flex justify-content-xl-end " >
                <div class="d-flex align-items-center">
                  <b >{{ data.rangeText }}</b>
                </div>
                <div class="d-grid p-10 d-lg-flex ">
                  <b-button @click="data.clickApply" variant="primary" v-if="!data.in_selection" class="acceptButton btn" style="margin-right: 8px">{{ $t('billing.applyLabel') }}</b-button>
                  <button @click="data.clickCancel" class="btn btn-secondary">{{ $t('billing.cancelLabel') }}</button>
                </div>
              </div>
            </date-range-picker>
            <div>
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  :placeholder="$t('search.make_search')"
              ></b-form-input>
            </div>
          </div>

          <div style="margin-top: 1rem;">
            <b-table
                style="max-height: calc(100vh - 35rem)"

                :items="sourcesCacheStates.data"
                :busy="isLoading"
                :filter="filter"
                :fields="fields"
                :empty-text="$t('billing.empty_text')"
                sticky-header
                responsive
                show-empty
                small
                head-variant="light"
            >
              <template #cell(source)="row">
                <div style="max-width: 50vh; white-space: break-spaces">
                  {{row.value }}
                </div>
              </template>
              <template #cell(released_at)="row">
                {{row.value | moment('DD.MM.YYYY')}}
              </template>

            </b-table>
          </div>

        </div>
      </div>
    </div>



  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CountryFlag from 'vue-country-flag'
import env from "@/configs/env.json";
import calendarTranslate from "@/configs/calendarTranslate";
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'



export default {
  name: "BaseSourceDate",
  components: {
    CountryFlag,
    DateRangePicker
  },
  setup() {
    return {
      moment,
      calendarTranslate
    }
  },
  data() {
    return {
      isLoading: false,
      inputValue: '',
      sortedByCount: {
        enable: false,
        fromAtoB: false
      },
      sortedByPrefix: {
        enable: false,
        fromAtoB: false
      },
      countries: [],
      env: env,
      table: {},
      dateRange: {
        startDate: moment(new Date()).subtract(7, 'days').toDate(),
        endDate:   moment(new Date()),
      },
      filter: null,
      // fields: [
      //   {
      //     key: 'source',
      //     label: this.$t('grafana.date_sources'),
      //     sortable: true
      //   },
      //   {
      //     key: 'count',
      //     label: this.$t('grafana.date_count'),
      //     sortable: true
      //   },
      //   {
      //     key: 'released_at',
      //     label: this.$t('billing.released_at'),
      //     sortable: true
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters("stats", ["mongoStats", "elasticStats", "phonePrefixStats", 'sourcesCacheStates']),
    count() {
      let count = 0;
      this.phonePrefixStats.forEach(item => {
        count = count + item.count
      })
      return this.beautyNumbers(count)
    },
    fields() {
      return [
        {
          key: 'source',
          label: this.$t('grafana.date_sources'),
          sortable: true
        },
        {
          key: 'count',
          label: this.$t('grafana.date_count'),
          sortable: true
        },
        {
          key: 'released_at',
          label: this.$t('billing.released_at'),
          sortable: true
        },
      ]
    }
  },
  mounted() {
    this.getStats();
  },

  methods: {
    getSourceCacheState(){
      let date = {
        created_from: moment(this.dateRange.startDate).format('X'),
        created_to: moment(this.dateRange.endDate).format('X'),
      }

      this.isLoading = true
      this.$store.dispatch('stats/getSourcesCacheStates', date).then(()=>{
        this.isLoading = false
      })
    },


    beautyNumbers(val) {
      let temp;
      if (typeof val !== 'undefined') {
        temp = val.toString()
        if (temp.length >= 7 && temp.length < 10) {
          let count = temp.length - 7;
          temp = temp.substr(0, temp.length - 3)
          let first = temp.substr(0, temp.length - 3)
          let lust = temp.substr(1 + count)
          return first + this.$t('base_source_date.separator') + lust + this.$t('base_source_date.million');
        } else if (temp.length >= 10) {
          let count = temp.length - 10;
          temp = temp.substr(0, temp.length - 6)
          let first = temp.substr(0, temp.length - 3)
          let lust = temp.substr(1 + count)
          return first + this.$t('base_source_date.separator') + lust + this.$t('base_source_date.billion');
        }
        return val.toLocaleString('ru')
      }
    },
    inputSearch() {
      let temp = [];
      if (this.inputValue === '') {
        this.countries = this.phonePrefixStats
      } else {
        this.phonePrefixStats.forEach((el) => {
          el.countries.forEach(item => {
            if (item.country.toUpperCase().includes(this.inputValue.toUpperCase())) {
              temp.push(el)
            }
          })

        })
        this.countries = temp;
      }
    },
    clearInputVal() {
      this.inputValue = '';
      this.countries = this.phonePrefixStats;
    },
    sortByCount() {
      this.sortedByCount.enable = true;
      this.sortedByPrefix.enable = false;
      this.sortedByCount.fromAtoB = !this.sortedByCount.fromAtoB;
      if (this.sortedByCount.fromAtoB) {
        this.countries.sort(function (a, b) {
          return b.count - a.count
        })
      } else {
        this.countries.sort(function (a, b) {
          return a.count - b.count
        })
      }
    },
    sortByPrefix() {
      this.sortedByPrefix.enable = true;
      this.sortedByCount.enable = false;
      this.sortedByPrefix.fromAtoB = !this.sortedByPrefix.fromAtoB;
      if (this.sortedByPrefix.fromAtoB) {
        this.countries.sort(function (a, b) {
          return +(a.prefix.toString() > b.prefix.toString()) || -(a.prefix.toString() < b.prefix.toString())
        })
      } else {
        this.countries.sort(function (a, b) {
          return -(a.prefix.toString() > b.prefix.toString()) || +(a.prefix.toString() < b.prefix.toString())
        })
      }
    },
    setTable() {
    },
    getStats() {
      this.isLoading = true;
      let table = null;
      let date = {
        created_from: moment(this.dateRange.startDate).format('X'),
        created_to: moment(this.dateRange.endDate).format('X'),
      }
      Promise.all([
        this.$store.dispatch('stats/getMongoStat'),
        this.$store.dispatch('stats/getElasticStat'),
        this.$store.dispatch('stats/getPhonePrefixStats'),
        this.$store.dispatch('stats/getSourcesCacheStates', date)
      ]).finally(() => {
        this.countries = this.phonePrefixStats.sort(function (a, b) {
          return b.count - a.count
        });
        this.$nextTick(() => {
        })
        this.isLoading = false;
      })
    },
  },
  watch: {
    'dateRange.endDate': function (){
      this.getSourceCacheState();
    },
    'dateRange.startDate': function (){
      this.getSourceCacheState();
    },
  }
}
</script>
<style scoped>
.daterangepicker.opensright {
	 margin-top: 30px;
}
 .daterangepicker.opensright {
	 left: 10px;
	 top: 30px;
	 right: auto;
}
 .first:hover .searchText {
	 display: none;
}
 .first:hover .searchInput {
	 display: inline-flex;
}
 .first {
	 transition: all 500ms ease;
}
 .searchInput {
	 display: none;
	 align-items: center;
}
 .info {
	 margin-left: 1rem;
	 padding-bottom: 2px;
	 font-weight: 600;
}
 .info:last-child {
	 padding-bottom: 10px;
}
 .country {
	 padding: 5px;
	 border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}
 .country:not(:last-child) {
	 border-bottom: solid 1px rgba(0, 0, 0, 0.3);
}
 .tableBlock {
	 width: 33%;
}
 .scroll-area {
	 height: 400px;
	 overflow: visible auto;
}
 .header {
	 background-color: #f2f7fb;
	 padding: 0.5em;
	 border-radius: 4px;
	 display: flex;
	 justify-content: flex-start;
}
 .header .sortButt {
	 text-align: center;
	 width: 33%;
}
 .header .sortButt:not(:last-child) {
	 border-right: solid 1px white;
}
 .base-block {
	 display: flex;
	 justify-content: center;
}
 .base-block .base-title {
	 margin-top: 5px;
	 margin-left: 10px;
}
 .content-header {
	 display: flex;
	 justify-content: center;
	 border-top-left-radius: 0.25rem;
	 border-top-right-radius: 0.25rem;
	 padding: 1rem;
	 background-color: white;
	 align-items: stretch;
	 box-shadow: 0 0.125em 0.25em #000;
	 font-size: 1.25em;
	 color: #363636;
	 font-weight: 600;
	 line-height: 1.125;
}
</style>
<style>
.table.table-sm td{
    padding: 4px 2rem
  }
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1), 0 11px 6px -7px rgba(43, 43, 43, 0.1);
}

body .border-checkbox-group.border-checkbox-group-primary {
  display: block;
}
body .daterangepicker {
  z-index: 10 !important;
  margin-top: 40px;
}
@media screen and (min-width: 768px){
  body .daterangepicker.show-ranges {
    min-width: 770px !important;
  }
}
@media screen and (max-width: 768px){
  body .daterangepicker.show-ranges {
    min-width: 340px !important;
  }
}

</style>
<style lang="scss">
body .daterangepicker .acceptButton{
  background-color: $primary-color !important;
}

body .daterangepicker .acceptButton:hover{
  background-color: #73b4ff !important;
}
.tables .nav-link{
  color: rgb(64, 153, 255);
}
.daterangepicker td.active{
  background-color: #4099ff;
}
.daterangepicker td.active:hover{
  background-color: #73b4ff;
}

.daterangepicker .ranges .active {
  background-color: $primary-color !important;
}
.daterangepicker .ranges .active:hover {
  background-color: #73b4ff !important;
}
</style>